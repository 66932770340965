import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './LandingHero.css';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const LandingHero = ({ showScroll, setHeaderClass, headerClass }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [images, setImages] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const videoRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();



  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const mediaFiles = isMobile 
      ? ['/landingPage/mobilecouple.mp4']
      : ['/landingPage/arunway.mp4'];
    
    setImages(mediaFiles);
    setIsLoading(true);
    setCurrentImage(0);
  }, [isMobile]);

  const scrollToFeatures = () => {
    featuresRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const { ref: featuresRef, inView: isInView } = useInView({
    threshold: 0.2,
    triggerOnce: false
  });

  const features = [
    {
      title: "Design",
      description: "Create your perfect wedding vision with our innovative AI-powered design tools",
      animation: "fade-right",
      path: "/design"
    },
    {
      title: "Explore", 
      description: "Discover unique ideas and get inspired by AI-generated designs tailored to your style",
      animation: "fade-up",
      path: "/explore"
    },
    {
      title: "Connect",
      description: "Find and book the perfect local vendors to bring your vision to life",
      animation: "fade-left",
    }
  ];

  useEffect(() => {

    const handleScroll = () => {
      requestAnimationFrame(() => {
        const scrollPosition = window.scrollY;
        
        if (scrollPosition > 50) {
          setHeaderClass?.('header-black');
        } else {
          setHeaderClass?.('header-transparent');
        }
      });
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll, { passive: true });
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="landing-hero">
      <div className="background-image-container">
        {images.map((media, index) => (
          <div
            key={`${media}-${index}`}
            className={`background-media ${index === currentImage ? 'active' : ''}`}
          >
            {media.endsWith('.mp4') ? (
              <video
                ref={videoRef}
                autoPlay
                muted
                loop
                playsInline
                preload="auto"
                key={media}
                className={`background-video ${isMobile ? 'mobile-video' : ''} ${isLoading ? 'loading' : ''}`}
                onLoadStart={() => setIsLoading(true)}
                onLoadedData={() => {
                  setIsLoading(false);
                  setIsVideoPlaying(true);
                }}
              >
                <source src={media} type="video/mp4" />
              </video>
            ) : (
              <div 
                className="background-image"
                style={{ backgroundImage: `url(${media})` }}
              />
            )}
          </div>
        ))}
      </div>
      
      <div className="content-overlay">
        <div className="prompt-content">
          <h1 className="main-title">Your Dream Wedding Starts Here</h1>
          <h2 className="subtitle">From AI-powered designs and unique inspirations to connecting with local vendors — Wedify is here to transform your wedding vision into reality</h2>
          <Link to="/design" className="start-button">Start Designing</Link>
        </div>
        <button 
          className="scroll-button"
          onClick={scrollToFeatures}
        >
          ↓
        </button>
      </div>

      <div className="features-section" ref={featuresRef}>
        <motion.div 
          className="features-content"
          initial={{ opacity: 0, y: 30 }}
          animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
          transition={{ duration: 0.8 }}
        >
          <h2 className="features-title">Plan Your Perfect Day</h2>
          <p className="features-subtitle">Visualize everything you need in one place</p>
          
          <div className="feature-grid">
            {features.map((feature, index) => (
              <motion.div
                key={feature.title}
                className={`feature-item ${feature.animation}`}
                initial={{ opacity: 0, y: 20 }}
                animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
                onClick={() => navigate(feature.path)}
                style={{ cursor: 'pointer' }}
              >
                <div className="feature-icon">{feature.icon}</div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default LandingHero;
