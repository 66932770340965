import axios from 'axios';

const sendToDiscord = async (message) => {
  const webhookUrl = process.env.REACT_APP_DISCORD_WEBHOOK_URL;
 

  try {
    const response = await axios.post(webhookUrl, { content: message });
  } catch (error) {
    console.error('Error sending message to Discord:', error);
  }
};

export default sendToDiscord;
        