import React, { useState, useEffect, useCallback } from 'react';
import Slider from 'react-slick';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useDesign } from './DesignContext';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Canvas.css';
import { getClosestColor } from './colorMapper';
import Spinner from './Spinner';
import { generateAuthToken } from './utils/encryptExternalID';
import { Circle } from 'lucide-react';
import PaymentPortal from './components/PaymentPortal';

const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};

const getColorInfo = (hexColor) => {
  const rgb = hexToRgb(hexColor);
  const namedColor = getClosestColor(hexColor);
  return {
    namedColor,
    rgb
  };
};

const Canvas = ({ 
  progress, 
  loading, 
  setLoading,
  generateVisualizations,
  isGenerationComplete
}) => {
  const {
    selectedTheme,
    selectedColor,
    selectedFlower,
    selectedSeating,
    selectedSeatingSubOption,
    stageVisualizationUrls,
    tableVisualizationUrls,
    stagePrompt,
    tablePrompt,
    selectedVenue,
    clearState
  } = useDesign();

  const [bookmarkedImages, setBookmarkedImages] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const [user, setUser] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [credits, setCredits] = useState(null);
  const [showCreditsModal, setShowCreditsModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const generateToken = useCallback(async () => {
    try {
      const token = await generateAuthToken();
      
      return token;
    } catch (error) {
      
      return null;
    }
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      
      setUser(currentUser);
      if (currentUser) {
        fetchCredits(currentUser);
      } else {
       
        setCredits(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleImageError = (url) => {
    console.error(`Failed to load image: ${url}`);
  };

  const handleBookmark = async (imageUrl, label, prompt) => {
    setIsUploading(true);
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      
      const formData = new FormData();
      formData.append('file', blob, 'image.jpg');

      const colorInfo = getColorInfo(selectedColor);
      const capitalizedColor = colorInfo.namedColor.charAt(0).toUpperCase() + colorInfo.namedColor.slice(1);

      const metadataValuesList = [
        { key: "theme", value: selectedTheme },
        { key: "flowers", value: selectedFlower },
        { key: "color", value: capitalizedColor },
        { key: "venue", value: selectedVenue === 'Indoor' ? 'Indoor' : 'Outdoor' }
      ];

      if (label === 'Table Decor') {
        let seatingDescription = selectedSeating;
        if (selectedSeating === 'Ghost Chair' && selectedSeatingSubOption) {
          seatingDescription = selectedSeatingSubOption;
        } else if (selectedSeatingSubOption) {
          seatingDescription = `${selectedSeating} (${selectedSeatingSubOption})`;
        }
        metadataValuesList.push({ key: "seating", value: seatingDescription });
      }

      const selectedOptions = JSON.stringify({ metadataValuesList });

      const token = await generateToken();
      if (!token) {
        throw new Error('Failed to generate authentication token');
      }

      const headers = {
        'contentType': 'jpeg',
        'authService': 'firebase',
        'externalClientId': user.uid,
        'prompt': prompt,
        'explorePage': 'true',
        'cwRed': colorInfo.rgb.r.toString(),
        'cwGreen': colorInfo.rgb.g.toString(),
        'cwBlue': colorInfo.rgb.b.toString(),
        'bookmarked': 'false',
        'selectedOptions': selectedOptions,
        'designCategory': 'wedding decor',
        'X-Auth-Token': token
      };

      const uploadResponse = await fetch('https://wedify.live/api/images/upload/auth/id', {
        method: 'POST',
        headers: headers,
        body: formData
      });

      if (!uploadResponse.ok) {
        const errorText = await uploadResponse.text();
        throw new Error(`HTTP error! status: ${uploadResponse.status}, message: ${errorText}`);
      }

      // Update the bookmarked status for this image
      setBookmarkedImages(prev => ({
        ...prev,
        [imageUrl]: { bookmarked: true }
      }));

      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 3000);
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    arrows: false,
  };

  const combineStageAndTableUrls = (stageUrls, tableUrls) => {
    const combined = [];
    const maxLength = Math.max(stageUrls.length, tableUrls.length);
    
    for (let i = 0; i < maxLength; i++) {
      if (i < stageUrls.length) {
        combined.push({ 
          url: stageUrls[i], 
          label: 'Stage Decor', 
          prompt: decodeURIComponent(stagePrompt) 
        });
      }
      if (i < tableUrls.length) {
        combined.push({ 
          url: tableUrls[i], 
          label: 'Table Decor', 
          prompt: decodeURIComponent(tablePrompt) 
        });
      }
    }
    
    return combined;
  };

  const combinedVisualizations = combineStageAndTableUrls(stageVisualizationUrls, tableVisualizationUrls);

  const renderCombinedCarousel = () => (
    <div className="carousel-container">
      <Slider {...settings}>
        {combinedVisualizations.map((item, index) => (
          <div key={index} className="slide-wrapper">
            <div className="image-wrapper">
              <img
                src={item.url}
                alt={`Generated ${item.label.toLowerCase()} visualization ${index + 1}`}
                className="generated-image"
                onError={() => handleImageError(item.url)}
              />
              <div className="image-label">{item.label}</div>
              <button 
                className={`save-button ${bookmarkedImages[item.url]?.bookmarked ? 'saved' : ''}`}
                onClick={() => handleBookmark(item.url, item.label, item.prompt)}
                disabled={bookmarkedImages[item.url]?.bookmarked}
              >
                {bookmarkedImages[item.url]?.bookmarked ? '✓ Saved' : 'Save'}
              </button>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );

  const renderMobileStacked = () => (
    <div className="mobile-stacked-images">
      {combinedVisualizations.map((item, index) => (
        <div key={index} className="mobile-image-item">
          <div className="image-label">{item.label}</div>
          <img 
            src={item.url} 
            alt={`Generated ${item.label.toLowerCase()} visualization ${index + 1}`}
            className="generated-image"
            onError={() => handleImageError(item.url)}
          />
          <button 
            className={`save-button ${bookmarkedImages[item.url]?.bookmarked ? 'saved' : ''}`}
            onClick={() => handleBookmark(item.url, item.label, item.prompt)}
            disabled={bookmarkedImages[item.url]?.bookmarked}
          >
            {bookmarkedImages[item.url]?.bookmarked ? 'Saved' : 'Save'}
          </button>
        </div>
      ))}
    </div>
  );

  const renderProgressCircle = () => {
    const steps = ['Venue', 'Theme', 'Color', 'Flowers', 'Seating'];
    const completedSteps = Math.floor((progress / 100) * steps.length);
    
    return (
      <div className="progress-circle-container">
        <div className="steps-progress">
          {steps.map((step, index) => (
            <div 
              key={step} 
              className={`step-indicator ${index < completedSteps ? 'completed' : ''}`}
            >
              <Circle 
                size={24} 
                className={`circle ${index < completedSteps ? 'filled' : ''}`} 
              />
              <span className="step-label">{step}</span>
            </div>
          ))}
        </div>
        <div className="progress-text">
          {completedSteps} of {steps.length} steps completed
        </div>
      </div>
    );
  };

  const handleVisualizationClick = () => {
    // Check if all required options are selected
    if (!selectedVenue || !selectedTheme || !selectedColor || !selectedFlower || !selectedSeating) {
      alert('Please complete all design options before generating');
      return;
    }

    // Check credits
    if (credits <= 0) {
      setShowCreditsModal(true);
    } else {
      generateVisualizations();
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const fetchCredits = async (currentUser) => {
    if (!currentUser) {
      return;
    }
    
    try {
    
      const token = await generateAuthToken();
      
      const response = await fetch(
        `https://wedify.live/api/login/generation/credits/left?authService=firebase&externalClientId=${currentUser.uid}`,
        {
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'X-Auth-Token': token
          }
        }
      );

      if (response.ok) {
        const creditsData = await response.text();
        
        setCredits(Number(creditsData));
      }
    } catch (error) {
      console.error('Error fetching credits:', error);
    }
  };

  return (
    <div className="canvas">
      {!isMobile && credits !== null && (
        <button 
          className="credits-display desktop-only"
          onClick={() => setShowCreditsModal(true)}
        >
          <span>{credits} credits</span>
        </button>
      )}

      <h2 className="canvas-title">Your AI Generated Decor</h2>
      {!loading && !stageVisualizationUrls.length && !tableVisualizationUrls.length && 
        renderProgressCircle()
      }
      <div className="canvas-grid">
        <div className="canvas-left"></div>
        <div className="canvas-center">
          {loading ? (
            <div className="loading-container">
              <Spinner text="Dream Wedding Generating" />
              <div className="progress-indicator">
                <div className="progress-bar">
                  <div className="progress" style={{ width: `${progress}%` }}></div>
                </div>
                <p>Progress: {progress}%</p>
                <p>{progress < 50 ? 'Generating Stage Decor...' : 'Generating Table Decor...'}</p>
              </div>
            </div>
          ) : stageVisualizationUrls.length > 0 || tableVisualizationUrls.length > 0 ? (
            <>
              <div className="desktop-only">
                {renderCombinedCarousel()}
              </div>
              <div className="mobile-only">
                {renderMobileStacked()}
              </div>
            </>
          ) : (
            <div className="start-message">
              <p>Click 'Generate Visualizations' to start</p>
            </div>
          )}
        </div>
        <div className="canvas-right"></div>
      </div>
      <button 
        onClick={handleVisualizationClick}
        className={`visualization-button ${(!selectedVenue || !selectedTheme || !selectedColor || !selectedFlower || !selectedSeating) ? 'disabled' : ''}`}
        disabled={!selectedVenue || !selectedTheme || !selectedColor || !selectedFlower || !selectedSeating}
      >
        {loading ? 'Generating...' : 
         credits <= 0 ? 'Out of Credits' : 
         !selectedVenue || !selectedTheme || !selectedColor || !selectedFlower || !selectedSeating ? 'Complete All Options' :
         (stageVisualizationUrls.length > 0 || tableVisualizationUrls.length > 0) ? 'Regenerate' : 'Generate Visualizations'}
      </button>
      {showMessage && (
        <div className="bookmark-message">
          {isUploading ? 'Uploading...' : 'Added To User Profile'}
        </div>
      )}
      {isUploading && (
        <div className="upload-overlay">
          <div className="spinner"></div>
          <p>Uploading image...</p>
        </div>
      )}
      <PaymentPortal 
        isOpen={showCreditsModal}
        onClose={() => setShowCreditsModal(false)}
        user={user}
        fetchCredits={fetchCredits}
        credits={credits}
      />
    </div>
  );
};

export default Canvas;

